$base-color: #3f51b5;

.pms-ws__setting-wrapper .MuiDataGrid-row {
  max-height: 100% !important;
} /* Style for the sticky first column */
.data-grid-container.scrolled-right .MuiDataGrid-cell:first-child {
  position: sticky !important;
  left: 0 !important;
  background-color: white !important;
  z-index: 1 !important;
  border-right: 1px solid #e0e0e0 !important;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorder.pms-property {
  display: inline;
}
.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorder.pms-property
  label {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.pms-pmsConnectionString__text-wrapper {
  white-space: pre-wrap;
  display: block;
}
.pms-ws__setting-wrapper .MuiDataGrid-windowContainer th:first-child,
.pms-ws__setting-wrapper .MuiDataGrid-windowContainer td:first-child {
  position: sticky;
  left: 0;
  background-color: #333; /* Optional: Background color to distinguish the fixed column */
  color: white; /* Optional: Text color for visibility */
  z-index: 1; /* Ensures the first column stays above other cells */
}
.pms-ws__setting-wrapper .MuiDataGrid-columnsContainer {
  position: -webkit-sticky; /* For Safari */
  position: sticky !important;
  top: 65px !important;
  z-index: 1000;
  background: white !important;
}
.pms-ws__setting-wrapper .MuiDataGrid-cell {
  line-height: 165% !important;
  max-height: 100% !important;
  white-space: break-spaces !important;
}
.pms-ws__setting-wrapper .MuiDataGrid-window {
  font-size: 0.675rem !important;
}

.pms-ws__setting-wrapper .MuiDataGrid-renderingZone {
  max-height: fit-content !important;
}
.pms-ws__setting-wrapper .MuiDataGrid-cell--withRenderer {
  align-items: normal !important;
}
.pms-pmsmodal__wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}
.pmstitle-title__wrapper {
  border-bottom: 1px solid gainsboro;
  padding-bottom: 5px;
}
.pmstitle-title__wrapper.t {
  margin-right: 20px;
}
.bulk-confirmation__container.pmsmodal_container {
  max-width: 90%;
  max-height: 87%;
  min-height: 97%;
  margin-top: 1%;
}
.shadowMgmt_wrapper .Shadow-Member-button__container {
  margin-top: 15px;
}
.shadowMgmt_wrapper .Shadow-Member-button__container button {
  min-width: 100px;
}

.pms-providerPage-box_wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  height: 500px;
  min-height: 500px;
  background-color: rgb(255, 255, 255);
}

.landingTbl-toolTip__wrapper {
  display: flex;
  justify-content: start;
  background-blend-mode: darken;
  padding-left: 10px;
  align-items: center;
  border-right: solid #e1e1e1 1px;
  border-top: solid #e1e1e1 1px;
  border-left: solid #e1e1e1 1px;
}

.shadowMgmt_wrapper.member .landingTbl-toolTip__container {
  margin-right: 0px;
  margin-left: 0px;
}
.shadowMgmt_wrapper .landingTbl-toolTip__container svg {
  color: $base-color;
}

.shadowMgmt_wrapper .landingTbl-txtSearch__wrapper {
  margin-bottom: 10px;
}

.pms-pmsConnectionString__wrapper .MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 20px;
  padding: 5px;
  max-height: 1600px;
}
.Shadow-modal__wrapper .Shadow-modal__container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  min-width: 700px;
  background-color: rgb(255, 255, 255);
}
.Shadow-modal__wrapper .Shadow-modal__container.bulk {
  min-width: 80%;
}
.Shadow-modal__container .Shadow-modal-title__container {
  display: flex;
  justify-content: space-between;
  background: $base-color;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  padding: 7px;
  padding-left: 20px;
  align-items: center;
}

.Shadow-modal__container .Shadow-modal-title__container svg {
  color: white;
}

.Shadow-modal__container .Shadow-modal-body__container {
  padding: 15px;
  padding-top: 40px;
}

.Shadow-modal__container .Shadow-modal-body__container .MuiTextField-root {
  width: 90%;
  left: 5%;
  margin-bottom: 10px;
}

.Shadow-modal__container .Shadow-modal-button__container {
  padding: 10px 0 15px;
  display: flex;
  justify-content: center;
}

.Shadow-modal__container .Shadow-modal-button__container button,
.memberconfiguration-form__wrapper button {
  width: 100px;
}

.shadowMgmt_wrapper.member .MTableToolbar-searchField-26 {
  width: 160px;
}
.shadowMgmt_wrapper.member .memberconfiguration-form__wrapper {
  background: white;
  padding: 20px;
}

.shadowMgmt_wrapper.member .memberconfiguration__wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
}
.shadowMgmt_wrapper.member
  .memberconfiguration__wrapper
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
  font-size: 18px;
}

.shadowMgmt_wrapper.member .memberconfiguration__wrapper .MuiFormControl-root {
  width: 98%;
  padding-bottom: 15px;
}
.MuiFormControl-root.smMbmrFrmcverKey {
  padding-bottom: 0px !important;
}
.shadowMgmt_wrapper.member
  .memberconfiguration__wrapper
  .pms-select__wrapper
  .MuiInputBase-root {
  width: 98%;
}

.materialTable-pms__wrapper .MuiTableCell-root.MuiTableCell-body {
  padding-left: 35px;
}

.materialTable-pms__wrapper .MuiTypography-root.MuiTypography-h6 {
  font-size: 12px;
}

.Shadow-modal__wrapper .shadow-memberModal__wrapper .MuiFormControl-root {
  width: 90%;
  left: 5%;
  margin-bottom: 10px;
}

.Mui-odd {
  background-color: #f6f7f7;
}
.Shadow-modal-body__container .sm-disabled,
.Shadow-modal-body__container .sm-disabled .MuiOutlinedInput-inputMarginDense {
  background: #f9f9f9;
  color: #707070;
}
.pms-sm-description::after {
  content: "Installation path will be available after the creation of the record.";
  color: #979393;
  top: -11px;
  position: relative;
  left: 20%;
  font-style: italic;
  font-size: 12px;
  font-weight: 400;
}
.Shadow-modal-title__container.docu{
  position: sticky;
  top: -1px;
  z-index: 2;
}
.bulk-confirmation__container.pmsmodal_container.docu{
  padding-top: 0;
  padding-bottom: 30px;
  width: 90%;
}
/* ToolTip classses */
.pms-member__tooltip {
  display: inline-block;
}
.pms-member__tooltip .tooltiptext {
  margin-left: -320px;
  width: 310px;
  visibility: hidden;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #aeaeae;
  position: absolute;
  z-index: 1;
  padding: 5px;
  margin-top: 124px;
  opacity: 0;
  transition: opacity 0.5s;
}

.pms-member__tooltip.wbSetting .tooltiptext {
  margin-top: 33px;
}

.pms-member__tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.landingTbl-txtSearch__wrapper.grid-webScheduler {
  min-height: 430px;
  padding: 1%;
  border: #cccccc 1px solid;
}
.landingTbl-txtSearch__wrapper.grid-webScheduler .columnSpan {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  min-height: 35px;
}

.pms-ws__setting-wrapper .pms-ws_subTitle {
  display: inline-block;
  font-size: 22px;
  font-weight: bolder;
}

.pms-ws-firstRow,
.pms-ws-secondRow {
  min-height: 100px;
}

.pms-ws-thirdRow {
  margin-top: 10px;
  min-height: 150px;
}

.pms-pmsConnectionString__wrapper
  textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
  overflow-y: scroll !important;
  font-size: 13px;
  max-height: 800px;
  min-height: 800px;
  height: 800px;
}

.pms-member__tooltip textarea {
  overflow-y: scroll !important;
  height: 100px;
  min-height: 100px;
  max-height: 100px;
}

.edenStatus-memberconfiguration-form__wrapper .pms-EdenWbWebScheduled {
  margin-top: 10px;
}
.pms-pmsConnectionString__wrapper .MuiTextField-root {
  font-size: 11px;
  max-height: 1635px;
}

.pms-member__tooltip.rbc textarea {
  font-size: 12px;
}

.shadow-bulk__container .pms-select__wrapper {
  width: 100%;
}

.shadow-bulk__container .actionButton__container {
  display: flex;
}

.shadow-bulk__container span#bulk-span-id,
.bulk-confirmation__container span#bulk-span-id {
  font-style: italic;
  font-size: 12px;
}

.shadow-bulk__container .bulk-details__wrapper .MuiTextField-root,
.bulk-confirmation__container .MuiTextField-root {
  width: 100%;
}
.pms-pmsmodal__wrapper
  .shadow-bulk__container
  .bulk-details__wrapper
  .MuiTextField-root,
.bulk-confirmation__container .MuiTextField-root {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  padding-right: 5px;
  padding-left: 5px;
}
.shadow-bulk__container .bulk-grid__wrapper {
  margin: 15px 0px;
}

.shadow-bulk__container .bulk-divider {
  margin: 30px;
}

.bulk-confirmation__container {
  background: white;
  max-width: 80%;
  margin: auto;
  margin-top: 5%;
  padding-bottom: 5px;
  padding-top: 5px;
  max-height: 80%;
  overflow: scroll;
  overflow-x: hidden;
}

.bulk-confirmation-grid__wrapper {
  margin: 20px 5%;
}
.bulk-confirmation__container .MuiTextField-root {
  margin-top: 15px;
  margin-bottom: 15px;
}
.shadow-bulk__container
  .MuiDataGrid-columnHeaderTitleContainer
  .MuiIconButton-label {
  visibility: hidden;
  display: none;
  cursor: pointer;
}

.MuiPaper-elevation8 {
  box-shadow: none;
}

.popper_hide {
  display: none;
}

.popper_show {
  display: block;
}
.pms-tcdataviewer-table-container {
}
.pms-tcdataviewer-textfield-container {
  width: 50%;
}

.pms-tcdataviewer-container table {
  border-collapse: collapse;
  table-layout: auto;
}

.pms-tcdataviewer-container th,
.pms-tcdataviewer-container td {
  border: 1px solid black;
  padding: 0px 10px;
}

.pms-tcdataviewer-top {
  margin-bottom: 5px;
  display: block;
  text-decoration: none;
  color: dodgerblue;
}

.pmsconfig-dataviewer-container {
  padding-top: 70px;
}

.pms-tc-dataviewer-bodywrapper {
  overflow: auto;
  max-height: 90em;
}

.pms-ws_subTitle.hf {
  padding-bottom: 10px;
}

.actionButton__container.hf {
  margin-top: 10px;
}

.pms-ws_subTitle.hf {
  font-weight: 600;
  display: block;
  padding-bottom: 0px;
}
.pms-ws_description.hf {
  display: block;
  padding-bottom: 5px;
}

.MuiGrid-item.pms_jobfiveTransferList {
  margin-top: 25px;
}
.pms_transferList {
  display: flex;
  align-items: center;
}
.pms_transferListColumns {
  max-height: 500px;
  min-height: 500px;
}

.MuiListItem-button.pms_transferListItem {
  padding-top: 0px;
  padding-bottom: 0px;
}

.MuiCheckbox-root.pms_transferListItemCheckBox {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pms_cardMasterTransfer {
  width: 23vw;
  height: 100%;
}
.MuiCardHeader-root.pms_cardHeaderTransfer {
  padding-top: 5px;
  align-items: flex-end;
}

.MuiCheckbox-root.pms_cardHeaderTransferCheckBox {
  padding-bottom: 0;
}

.pms_cardHeaderTransferList {
  max-height: 85%;
  overflow-y: scroll;
  height: 85%;
}
